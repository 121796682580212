// ! ---------- PAGE TITLE


.page-title {}

.page-title__title {
    display: inline; }

.page-title__info {
    display: inline-block; }

.page-title__title {
    margin-right: .5em; }




// ! ---------- SCROLL ANCHOR


// Offset scroll anchor by page padding

.scroll-anchor {
    &:before {
        content: '';
        display: block;
        position: relative;
        width: 0;
        @include grid-gutter-property(height, $gutter: $global-padding);
        @include grid-gutter-property(margin-top, $gutter: $global-padding, $factor: -1); } }




// ! ---------- SCROLL CONTAINER


// Hide scrollbars by expanding width of inner element

@mixin scroll-container {
    width: 100vw;
    height: 100vh;
    .needs-vh-correction & {
        height: calc(100vh - var(--vh-offset)); }
    position: relative;
    overflow: hidden; }

@mixin scroll-container--inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    width: calc(100% + #{$scroll-container-inset});
    padding-right: $scroll-container-inset;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }

.scroll-container--large-only {
    @include layout--two-column-lists {
        @include scroll-container;

        .scroll-container__inner {
            @include scroll-container--inner; } } }

.scroll-container {
    @include scroll-container;

    .scroll-container__inner {
        @include scroll-container--inner; } }




// ! ---------- LIST ITEMS



%list {}

%list-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:not(:last-child) {
        @extend %list-item-padding; } }

%list-item--larger-padding {
    &:not(:last-child) {
        @extend %list-item-padding--larger; } }

%list-item--large-padding {
    &:not(:last-child) {
        @extend %list-item-padding--large; } }

%list-item--larger-padding--always {
    @extend %list-item-padding--larger;
    &:not(:last-child) {
        @extend %list-item-padding--larger; } }

%list-item--large-padding--always {
    @extend %list-item-padding--large;
    &:not(:last-child) {
        @extend %list-item-padding--large; } }

%list-item-column {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;

    @include layout--two-column-lists {
        width: 50%; } }

%list-item-info {
    @extend %list-item-text-padding;
    order: 2;

    @if $show-list-item-on-hover {
        transition: transition(opacity);

        [data-whatintent='mouse'] & {
            opacity: 0; }
        [data-whatintent='mouse'] .hover-container:hover & {
            opacity: 1; }

        [data-whatintent='initial'] & {
            @include breakpoint(medium) {
                opacity: 0; } }
        [data-whatintent='initial'] .hover-container:first-child & {
            opacity: 1; }

        [data-whatintent="touch"] & {
            opacity: 1; } } }

%list-item-text {
    p {
        max-width: 26em; } }

%list-item-media {
    order: 1; }

%list-table {
    table-layout: fixed;

    td:first-child {
        width: 50%;
        @include layout--two-column-lists {
            width: 33.333%; } } }

%list-table--wide {
    @extend %list-table;
    td:first-child {
        width: 75%;
        @include breakpoint(large) {
            width: 83.333%; } } }

%list-item-padding {
    @include layout--two-column-lists {
        @include global-padding(bottom, $factor: 4); } }

%list-item-padding--larger {
    @include global-padding(bottom, $factor: 4);
    @include layout--two-column-lists {
        @include global-padding(bottom, $factor: 6); } }

%list-item-padding--large {
    @include global-padding(bottom, $factor: 4);
    @include layout--two-column-lists {
        @include global-padding(bottom, $factor: 8); } }

%list-item-text-padding {
    @include global-padding(left right);
    @include global-padding(top);
    @include layout--one-column-lists {
        @include global-padding(bottom); } }




// ! ---------- INFO


.info {
    header {
        @extend %hide-visually; } }


.info__main {
    transition: transition(opacity, $factor: 2);
    .is-resizing & {
 } }        // opacity: 0

.info__section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include global-padding(top left right);

    padding-bottom: 3 * $one-line-height;
    @include layout--two-column-lists {
        padding-bottom: 8 * $one-line-height; } }

.info__header {
    flex: 0 0 100%;
    @include layout--two-column-lists {
        flex-basis: 50%; }

    @extend %font-header;
    margin-bottom: $one-line-height;
    &:empty {
        margin-bottom: 0; } }

.info__content {
    @extend %font-large--two-columns;
    flex: 0 0 100%;
    @include layout--two-column-lists {
        flex-basis: 50%; } }

.info__block {}

.info__block + .info__block {
    margin-top: 2 * $one-line-height; }

.info__block--about {
    @extend %font-large--one-column; }

.info__section--additional-info {

    table {
        table-layout: fixed;

        // Mobile only: limit width to 50% and align
        // text in last cell to right

        @include layout--one-column-lists {
            @include breakpoint(small) {
                width: 50% !important;
                td:last-child {
                    text-align: right; }
                td:only-child {
                    text-align: inherit; } } } } }

.fp-section {}

.fp-section.active + .fp-section {}

.fp-enabled div[style^='z-index:999'] {
    display: none !important; }

@if $limit-info-quote-width {
    .info__block--quotes {
        width: 80%;
        max-width: 28em; } }




// ! ---------- INFO SLIDES


.info-slide-wrapper {
    position: relative;
 }    //border: 1px solid green

.info-slide {
    &.is-not-tallest {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0; }

    //border: 1px solid yellow
    .js & {
        visibility: hidden;
        &.is-active {
            visibility: visible; } } }

.info-slide-nav {
    margin-top: $one-line-height--relative;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1; }

.info-slide-nav__column {
    flex: 0 0 50%; }

.info-slide-nav__button {
    cursor: pointer;
    &::before {
        content: '['; }
    &::after {
        content: ']'; } }




// ! ---------- INFO TABLE


.info-table {

    table-layout: fixed;

    td:first-child {
        width: 75%; // 66.67%
        @include breakpoint(small) {
            width: 83.3%; } }

    &.num-columns--2 {
        td:first-child {
            width: 83.33%;
            @include breakpoint(small) {
                width: 91.7%; } }
        td:nth-child(2) {
            width: 0;
            padding: 0; } }

    &.num-columns--3 {
        td:nth-child(2) {
            vertical-align: bottom;
            @extend %font-normal; } }

    td:last-child {
        span {
            @extend %font-normal; }
        text-align: right;
        width: 8.33%; // 16.67%
        @include breakpoint(small) {
            width: 8.33%; } } }

.info-table-group + .info-table-group {
    tr:first-child td {
        padding-top: $one-line-height--absolute; } }

.info-table__toggle {
    cursor: pointer;
    span {
        @extend %hide-visually; }
    &:before {
        content: '[' attr(data-text-show) ']';
        .is-toggled & {
            content: '[' attr(data-text-hide) ']'; } } }




// ! ---------- PROJECT LIST


%project-header {
    @extend %font-header;
    @include global-padding(); }

.project-list {}

.project-list__project {
    @extend %list-item;
    @extend %list-item--large-padding;
    @include layout--one-column-lists {
 } }        /// @extend %list-item--large-padding

.project-preview {}

.project-preview__title {
    flex: 0 0 100%;
    @extend %project-header;
    @include layout--one-column-lists {
        @include hide-visually; }
    @include layout--two-column-lists {
        flex-basis: percentage(2/12);

        .project-list__project:first-child & {
 } } }            // visibility: hidden

.project-preview__index {
    flex: 0 0 100%;
    @include layout--two-column-lists {
        flex-basis: percentage(10/12); } }




// ! ---------- PROJECT


.project {
    .page-title__inner {
        max-width: calc(100vw - 4em); } } // Don't overlap "back" link

.project__actions {
    display: inline-block;
    opacity: 1;
    transition: transition(opacity); }

.project__index-toggle {
    cursor: pointer; }

.project__index-toggle-off {
    pointer-events: none;
    cursor: pointer;
    .is-index-visible & {
        pointer-events: auto; } }

.back-link {
    display: inline-block;
    position: fixed;
    bottom: -$vertical-align-fix;
    right: 0;
    z-index: $z-index-back;
    text-align: right;
    @extend %font-header;
    @include global-padding();
    @include layout--two-column-lists {
        right: auto;
        left: 0; } }

.project__back-link {
    display: none; }

.info__back-link {
    display: none;
    @include layout--one-column-lists {
        width: 100%;
        background: palette('background');
        @include overlay-opacity; } }

.project__index-toggle {
    span {
        @extend %hide-visually; }
    &::before {
        content: '['; }
    &::after {
        content: attr(data-text-off) ']';
        .is-index-visible & {
            content: attr(data-text-on) ']'; } } }


@include scrollable-overlay('.project__index', '.is-index-visible .project__index', $z-index: $z-index-index);



// ! ---------- SLIDE INDEX


.slide-index {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: $one-line-height * 3;

    figure {
        cursor: pointer; }

    @include layout--two-column-lists {
        width: 50vw;
        margin-left: 50vw; } }

.index-image {
    flex: 0 0;
    flex-basis: percentage(5/12);
    margin-right: percentage(1/12);
    padding-bottom: 100vw * 1/12; }

.index-image--width-xs,
.index-image--width-s, {
    flex-basis: percentage(4/12); }

@include layout--two-column-lists {
    .index-image {
        flex-basis: percentage(1.5/6);
        margin-right: percentage(0.5/6);
        padding-bottom: percentage(0.5/6);
        padding-bottom: 0.5/6 * 100vw * 6/12; }
    .index-image--width-xs {
        flex-basis: percentage(1/6); }
    .index-image--width-s {
        flex-basis: percentage(1/6); } }




// ! ---------- THUMBNAIL LIST


.thumbnail-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start; }

.thumbnail,
.thumbnail-list__title {
    flex: 0 0;
    flex-basis: percentage(5/12);
    margin-right: percentage(1/12);
    padding-bottom: 100vw * 1/12; }

.thumbnail-list__title {
    @extend %project-header; }

.thumbnail--width-xs,
.thumbnail--width-s, {
    flex-basis: percentage(4/12); }

@include layout--two-column-lists {
    .thumbnail-list__title {
        display: none; }
    .thumbnail {
        flex-basis: percentage(2.5/10);
        margin-right: percentage(0.5/10);
        padding-bottom: percentage(0.5/10);
        padding-bottom: 0.5/10 * 100vw * 10/12; }
    .thumbnail--width-xs {
        flex-basis: percentage(0.5/10); }
    .thumbnail--width-s {
        flex-basis: percentage(1.5/10); }
    .thumbnail--width-l {
        flex-basis: percentage(3.5/10); }

    .thumbnail--shift-s {
        margin-left: percentage(1/10); } }

.thumbnail-list a img {
    -webkit-transform: translate3d(0,0,0); }

.thumbnail--video a {
    position: relative;
    display: block;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

    iframe {
        pointer-events: none; } }

[data-whatintent='mouse'] {
    .thumbnail-list a:hover {
        animation: highlight-thumb 1.5s ease-in-out; } }
[data-whatintent='touch'] {
    .thumbnail-list {
        -webkit-tap-highlight-color: rgba(255, 255, 255, .2); } }

@keyframes highlight-thumb {
    0% {
        opacity: 1; }
    2% {
        opacity: .9; }
    100% {
        opacity: 1; } }



// ! ---------- BOOKS


.book-list {}

.book {
    @extend %list-item;
    @extend %list-item--larger-padding--always; }

.book__info,
.book__slideshow {
    @extend %list-item-column; }

.book__info {
    @extend %list-item-info; }

.book_text {
    table {
        @extend %list-table; } }

.book__slideshow {
    @extend %list-item-media; }

.book__header {
    display: flex;
    flex-direction: row; }

.book__title {
    flex-basis: 80%;
    flex-grow: 1;
    flex-shrink: 0; }

.book__date {
    flex-basis: 20%;
    flex-grow: 0;
    flex-shrink: 1;
    text-align: right;
    white-space: nowrap; }




// ! ---------- EXHIBITIONS


.exhibition-list {}

.exhibition {
    @extend %list-item;
    @extend %list-item--larger-padding--always; }

.exhibition__info,
.exhibition__slideshow {
    @extend %list-item-column; }

.exhibition__info {
    @extend %list-item-info; }

.exhibition_text {
    @extend %list-item-text;
    table {
        @extend %list-table--wide; } }

.exhibition__slideshow {
    @extend %list-item-media; }




// ! ---------- SLIDESHOW


$vw-col: 100vw / 12;

.slideshow {
    width: 100%;
    height: 100vh;

    .needs-vh-correction & {
        height: calc(100vh - var(--vh-offset)); } }

.slideshow__inner {
    pointer-events: none;
    z-index: $z-index-slide; }

.swiper-wrapper {
    transition-timing-function: $transition-easing--slideshow; }

.swiper-slide-active {

    .js-slideshow-next,
    .js-slideshow-prev {
        pointer-events: auto; }
    .js-slide-content {
        pointer-events: auto; } }



.slideshow__slide:first-child .js-slideshow-prev,
.slideshow__slide:last-child .js-slideshow-next {
    pointer-events: none; }


// Hide next and previous unless current slide is being hovered

@if $show-prev-next-slide-on-hover {

    .slideshow__slide {
        transition: 250ms opacity;
        [data-whatintent="mouse"] .is-mouse-idle & {
            opacity: 0; }
        &.swiper-slide-active {
            opacity: 1 !important; } } }

.slide__slide-inner {
    perspective: 1000px;
    backface-visibility: hidden; }

// Image / video / text wrappers

.slide__media,
.slide__text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }

.slide__media {

    user-select: none;

    transform: translate3d(0, 0, 0);
    transition: 750ms transform;
    transition-timing-function: $transition-easing--slideshow;
    will-change: transform;

    .is-resizing &,
    .slideshow--no-animation & {
        transition: none; }

    backface-visibility: hidden;
    perspective: 1000;
    font-smoothing: antialiased;
    transform-style: preserve-3d;

    .swiper-slide-prev & {
        transform: translate3d(0, 0, 0); }
    .swiper-slide-next & {
        transform: translate3d(#{-0.5 * $vw-col}, 0, 0); }

    img {
        object-fit: contain;
        object-position: 0% 0%; }

    .slide__height-container,
    img {
        max-height: 100vh;
        max-height: calc(100vh - var(--caption-height));
        .needs-vh-correction & {
            max-height: calc(100vh - var(--vh-offset));
            max-height: calc(100vh - var(--caption-height) - var(--vh-offset)); } } }

.TextformatterVideoEmbed iframe {
    top: -.5% !important;
    left: -.5% !important;
    width: 101% !important;
    height: 101% !important; }

.slide__height-container {
    overflow: hidden; }

.slide__placeholder {
 }    // background: palette('placeholder')

.slide__placeholder-text {
    @include global-padding(top right);
    @extend %font-header;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transition(padding);

    .swiper-slide-active & {
        @include global-padding(left); } }



// Text slides

.slide__text {
    width: 100vw;
    width: calc(100vw + 40px);
    max-height: 100vh;
    .needs-vh-correction & {
        max-height: calc(100vh - var(--vh-offset)); }

    .swiper-slide-active & {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        pointer-events: auto; } }

.slide__text-inner {
    width: 100vw;
    @extend %font-header;
    @include global-padding(top bottom);
    @include layout--one-column-lists {
        @include global-padding(left); }

    > div {
        width: percentage(10/12);
        transform: translate3d(0, 0, 0);
        padding-bottom: 5 * $one-line-height;
        @include layout--two-column-lists {
            width: percentage(5/12);
            transform: translate3d(#{6 * $vw-col}, 0, 0); } } }

// Captions beneath images / videos

.slide__caption {
    @include global-padding(top bottom);
    @include breakpoint(small down) {
        @include global-padding(left); }
    &:empty {
        display: none; } }



// Single images

.slide__media--single {

    width: percentage(9/12);
    .slide--width-s & {
        width: percentage(9/12); }
    .slide--width-l & {
        width: percentage(10/12); }

    @include layout--two-column-lists {

        transform: translate3d(#{6 * $vw-col}, 0, 0);

        width: percentage(4/12);
        .slide--width-s & {
            width: percentage(3/12); }
        .slide--width-l & {
            width: percentage(5/12);
            width: percentage(8/12);
            transform: translate3d(#{3 * $vw-col}, 0, 0); }

        .swiper-slide-prev & {
            transform: translate3d(#{8.5 * $vw-col}, 0, 0); }
        .swiper-slide-prev.slide--width-s & {
            transform: translate3d(#{9.5 * $vw-col}, 0, 0); }
        .swiper-slide-prev.slide--width-l & {
            transform: translate3d(#{7.5 * $vw-col}, 0, 0);
            transform: translate3d(#{4.5 * $vw-col}, 0, 0); }

        .swiper-slide-next.slide--width-l & {
            transform: translate3d(#{-0.5 * $vw-col}, 0, 0); } } }

// Videos

.slide__media--autoplay {
    @include breakpoint(large) {
        iframe {
            pointer-events: none; } } }

.slide__media--center {

    width: percentage(9/12);
    .slide--width-s & {
        width: percentage(9/12); }
    .slide--width-l & {
        width: percentage(10/12); }

    @include layout--two-column-lists {

        transform: translate3d(#{6 * $vw-col}, 0, 0);

        width: percentage(4/12);
        .slide--width-s & {
            width: percentage(3/12); }
        .slide--width-l & {
            width: percentage(6/12);
            width: percentage(8/12);
            transform: translate3d(#{3 * $vw-col}, 0, 0); }

        .swiper-slide-prev & {
            transform: translate3d(#{8.5 * $vw-col}, 0, 0); }
        .swiper-slide-prev.slide--width-s & {
            transform: translate3d(#{9.5 * $vw-col}, 0, 0); }
        .swiper-slide-prev.slide--width-l & {
            transform: translate3d(#{6.5 * $vw-col}, 0, 0);
            transform: translate3d(#{4.5 * $vw-col}, 0, 0); }

        .swiper-slide-next.slide--width-l & {
            transform: translate3d(#{-0.5 * $vw-col}, 0, 0); } } }

// Diptychs

.slide__media--double {

    width: percentage(4/12);

    &:first-child {
        transform: translate3d(0, 0, 0); }
    &:last-child {
        transform: translate3d(#{5 * $vw-col}, 0, 0); }

    .swiper-slide-next &:first-child {
        transform: translate3d(#{-0.5 * $vw-col}, 0, 0); }
    .swiper-slide-next &:last-child {
        transform: translate3d(#{4.5 * $vw-col}, 0, 0); }

    @include layout--two-column-lists {

        width: percentage(3/12);

        &:first-child {
            transform: translate3d(#{2.5 * $vw-col}, 0, 0); }
        &:last-child {
            transform: translate3d(#{6 * $vw-col}, 0, 0); }

        .swiper-slide-prev &:first-child {
            transform: translate3d(#{6 * $vw-col}, 0, 0); }
        .swiper-slide-prev &:last-child {
            transform: translate3d(#{9.5 * $vw-col}, 0, 0); }

        .swiper-slide-next &:first-child {
            transform: translate3d(#{-0.5 * $vw-col}, 0, 0); }
        .swiper-slide-next &:last-child {
            transform: translate3d(#{3 * $vw-col}, 0, 0); } } }

.slide--align-center .slide__media:first-child,
.slide--align-2-center .slide__media:last-child {

    figure {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%); } }

.slideshow__arrow-left,
.slideshow__arrow-right {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: $z-index-slide-arrow;
    @extend %no-tap-highlight;

    &.is-disabled {
        pointer-events: none; } }

.slideshow__arrow-left {
    left: 0; }

.slideshow__arrow-right {
    right: 0; }




// ! ---------- SLIDESHOW (INLINE)


.inline-slideshow {}

.inline-slideshow__inner {}

.inline-slideshow__slide {}

.inline-slideshow__pagination {
    width: 50%;
    margin-left: 50%;
    @include global-padding(top right); }

.inline-slideshow__arrow-left {
    @extend .slideshow__arrow-left;
    cursor: grab; }

.inline-slideshow__arrow-right {
    @extend .slideshow__arrow-right;
    cursor: grab; }
