// ! ---------- GRID OVERLAY

$grid-color: palette('grid');
$column-width: percentage(1 / $grid-column-count);

body {
    &::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 100vh;
        content: '';
        background-image: repeating-linear-gradient(to right, $grid-color, $grid-color $column-width, transparent $column-width, transparent $column-width * 2);
        background-size: 100% 100%;
        z-index: $z-index-grid;
        pointer-events: none;
        transition: transition(opacity);
        opacity: 0; }

    &.is-user-logged-in.is-grid-visible {
        &::before {
            opacity: .05; } } }




// ! ---------- PAGE

.is-leaving-page {

    body {
        overflow: hidden;
        max {} }

    .page {
        max-width: 100vw;
        max-height: 100vh;
        transition: transition(transform, $factor: 5, $easing: $transition-easing--overlay-in);
        transform: translateX(-100%); } }



// ! ---------- HEADER


.page__header {
    @extend %font-large; }




// ! ---------- FOOTER


.page__footer {
    @extend %hide-visually; }




// ! ---------- LOGO


.logo,
.page-title {
    position: fixed;
    bottom: -$vertical-align-fix;
    z-index: $z-index-logo;
    user-select: none;
    @include global-padding();
    @extend %font-header;

    background: palette('background');
    @include overlay-opacity;
    width: 100%;

    @include layout--two-column-lists {
        top: 0;
        bottom: auto;
        background: transparent;
        width: auto; } }

.logo {
    transition: transition(opacity);
    visibility: hidden;

    .template-home & {
        visibility: visible; }

    @include layout--two-column-lists {
        .template-project-list &, {
            visibility: visible;
            opacity: 0; }
        .template-project-list.is-content-scrolled-down & {
            opacity: 1; } } }

.page__navigation-toggle {
    @extend %neutral-button;
    position: fixed;
    bottom: -$vertical-align-fix;
    z-index: $z-index-logo;
    user-select: none;
    @include global-padding();
    @extend %font-header;

    width: 100%;
    background: palette('background');
    @include overlay-opacity;

    @include layout--one-column-lists {
        .template-project & {
            visibility: hidden; } }

    @include layout--two-column-lists {
        background: transparent;
        width: auto; }

    .template-home &, {
        visibility: hidden; } }


// ! ---------- NAVIGATION


$e-in: $transition-easing--overlay-in;
$e-out: $transition-easing--overlay-out;
$overlay-transition-transform--invisible: transition(transform, $easing: $e-in, $factor: 3);
$overlay-transition-transform--visible: transition(transform, $easing: $e-out, $factor: 3);
$overlay-transition-opacity--invisible: transition(opacity, $easing: $e-in, $factor: 3, $delay: $transition-duration * 2);
$overlay-transition-opacity--visible: transition(opacity, $easing: $e-out, $factor: 0.1, $delay: 0s);

$overlay-transition--invisible: $overlay-transition-transform--invisible, $overlay-transition-opacity--invisible;
$overlay-transition--visible: $overlay-transition-transform--visible, $overlay-transition-opacity--visible;

%scrollable-overlay--invisible {
    position: fixed;
    top: 0;
    left: 0;
    right: -$scroll-container-inset;
    bottom: 0;
    background: palette('background');

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    // Slide nav up from below

    pointer-events: none;
    transform: translate3d(0, 100vh, 0);
    transition: $overlay-transition--invisible;
    opacity: 0;

    // Larger screens: slide down from above

    @include layout--two-column-lists {
        transform: translate3d(0, -99.95%, 0); }

    .is-resizing & {
        transition: none; } }

%scrollable-overlay--visible {
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
    opacity: 1;
    transition: $overlay-transition--visible; }

%scrollable-overlay--visible-transparent {
    opacity: $overlay-alpha; }

%scrollable-overlay--firefox-border-fix {
    border-bottom: 1px solid palette('background'); }

@mixin scrollable-overlay($selector, $visible-selector, $transparent: false, $z-index: 99) {
    #{$selector} {
        @extend %scrollable-overlay--invisible;
        z-index: $z-index; }
    #{$visible-selector} {
        @extend %scrollable-overlay--visible;
        @if $transparent {
            @extend %scrollable-overlay--visible-transparent; } }


    @-moz-document url-prefix() {
        #{$selector} {
            @extend %scrollable-overlay--firefox-border-fix; } } }

@include scrollable-overlay('.page__navigation', '.is-nav-visible .page__navigation', $transparent: true, $z-index: $z-index-menu);
@include scrollable-overlay('.page__navigation', '.template-exhibition-list.is-nav-visible .page__navigation', $transparent: true, $z-index: $z-index-menu);
@include scrollable-overlay('.page__navigation', '.template-book-list.is-nav-visible .page__navigation', $transparent: true, $z-index: $z-index-menu);


// ! ---------- MENU


.menu {

    padding-right: $scroll-container-inset;

    ul {
        margin-bottom: 1em * $lineheight-s; } }

.menu__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include global-padding(top bottom);

    // First row = above the fold

    &:first-child {
        min-height: 100vh;
        .needs-vh-correction & {
            min-height: calc(100vh - var(--vh-offset)); } }

    // Last row = representation / dates
    // Below the fold on mobile

    // Two-col layout doesn't need min-height on inner rows
    .menu__row {
        @include layout--two-column-lists {
            min-height: 0; } } }

.menu__row--outer {
    @include global-padding($factor: 0); }

.menu__index-link {
    flex: 0 0 50%;
    order: 2;
    @include global-padding(left);
    @include layout--one-column-lists {
        margin-top: auto;
        position: relative;
        top: $vertical-align-fix; }
    @include layout--two-column-lists {
        flex-basis: 50%;
        order: 1; } }

.menu__close--single-column {
    flex: 0 0 50%;
    order: 2;
    margin-top: auto;
    position: relative;
    top: $vertical-align-fix;
    @include layout--two-column-lists {
        display: none; } }

.menu__close--multi-column {
    flex-basis: 25%;
    order: 3;
    margin-right: auto;
    margin-top: auto;
    @include global-padding(left);
    position: relative;
    top: $vertical-align-fix;
    display: none;
    @include layout--two-column-lists {
        display: block; } }

.menu__button {
    @extend %neutral-button;
    &::before {
        content: '['; }
    &::after {
        content: ']'; } }

.menu__main {
    flex: 0 0 100%;
    order: 1;
    @include layout--two-column-lists {
        flex-basis: 50%;
        order: 2; }

    display: flex;
    flex-wrap: wrap;
    margin-bottom: $one-line-height;

    h2, h3, h4 {
        span {
            @extend %font-normal;
            &::before {
                content: '('; }
            &::after {
                content: ')'; } } } }

.menu__projects,
.menu__links {
    flex: 0 0 50%;
    @include global-padding(right); }

.menu__projects {
    @include layout--one-column-lists {
        @include global-padding(left); } }

.menu__links {
    a.external {
        @extend %font-normal;
        line-height: $lineheight-xs;
        &::before {
            content: '['; }
        &::after {
            content: ']'; } } }

.menu__info {

    flex: 0 0 100%;
    order: 3;
    @include global-padding(right);
    @include layout--one-column-lists {
        position: relative;
        top: $vertical-align-fix;
        @include global-padding(left);
        & + .menu__info {
            margin-top: $one-line-height; } }

    @include layout--two-column-lists {
        flex-basis: 25%;
        order: 4;
        margin-top: auto;
        position: relative;
        top: $vertical-align-fix; }

    table {
        table-layout: fixed;

        // Mobile only: limit width to 50% and align
        // text in last cell to right

        @include layout--one-column-lists {
            @include breakpoint(small) {
                width: 50% !important;
                @if true {
                    td:last-child {
                        text-align: right; }
                    td:only-child {
                        text-align: inherit; } } } } } }
