// ! ---------- FONTS


$font-grotesque: 'GrotesqueMTStd';
$font-stack-default: $font-grotesque, 'Arial', sans-serif;

@include font-face($font-grotesque, normal, normal, 'GrotesqueMT/GrotesqueMTStd', $woff2: false);
@include font-face($font-grotesque, normal, italic, 'GrotesqueMT/GrotesqueMTStd-Italic', $woff2: false);

// There's no bold, so use regular variant for both declarations

@include font-face($font-grotesque, bold, normal, 'GrotesqueMT/GrotesqueMTStd', $woff2: false);
@include font-face($font-grotesque, bold, italic, 'GrotesqueMT/GrotesqueMTStd-Italic', $woff2: false);




// ! ---------- TYPOGRAPHY


%basic-typography {
    font-family: $font-stack-default;
    font-weight: normal;
    letter-spacing: $letterspacing-base; }

%font-normal {
    font-size: $fontsize-m--mobile;
    // line-height: $lineheight-m
    @include layout--two-column-lists {
        font-size: $fontsize-m; } }

%font-large {
    font-size: $fontsize-l--mobile;
    line-height: $lineheight-s;
    @include layout--two-column-lists {
        font-size: ($fontsize-l--mobile + $fontsize-l) / 2; } // medium
    @include breakpoint(large) {
        font-size: $fontsize-l; } }

%font-large--two-columns {
    @include layout--two-column-lists {
        line-height: $lineheight-s;
        font-size: ($fontsize-m--mobile + $fontsize-l) / 2; } // medium
    @include breakpoint(large) {
        font-size: $fontsize-l; } }

%font-large--one-column {
    @include breakpoint(small only) {
        line-height: $lineheight-s;
        font-size: ($fontsize-m-l--mobile + $fontsize-l) / 2; } } // medium

%font-header {
    font-size: $fontsize-m-l--mobile;
    line-height: $lineheight-s;
    @include layout--two-column-lists {
        font-size: ($fontsize-m-l--mobile + $fontsize-l) / 2; } // medium
    @include breakpoint(large) {
        font-size: $fontsize-l; } }


%header-style {
    font-style: italic; }

@mixin overlay-opacity {
    opacity: $overlay-alpha; }

%overlay-opacity {
    @include overlay-opacity; }





// ! ---------- MEDIA QUERIES


// Save breakpoints to meta tag using Foundation utility method
// Read back in via JS

.js-breakpoints {
    font-family: '#{-zf-bp-serialize($breakpoints)}'; }



// ! ---------- BASIC STYLES


html {
    @extend %basic-typography;
    font-size: $fontsize-base;

    @include breakpoint(xxxlarge) {
        font-size: $fontsize-xl-vw; }

    box-sizing: border-box;
    --vh-offset: 0;
    --caption-height: 0; }

*,
*::before,
*::after {
    box-sizing: inherit; }

body {
    background: palette('background');
    color: palette('text');
    font-size: $fontsize-m;
    line-height: $lineheight-base;

    @if $font-smoothing {
        -webkit-font-smoothing:  antialiased;
        -moz-osx-font-smoothing: grayscale; }

    -ms-overflow-style: none; }

::-webkit-scrollbar {
    display: none; }

a {
    color: palette('text');
    text-decoration: none;
    transition: transition(color);

    &:hover, &.hover {
        text-decoration: none;
        color: palette('highlight'); } }

small {
    @extend %font-normal; }

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic; }

table {
    width: 100% !important; }
thead {
    display: none; }
td {
    &:not(:last-child) {
        @include global-padding(right); } }

td:empty {
    &:after {
        content: "\00a0"; }
 }    // min-height: $one-line-height

input,
td {
    @extend %basic-typography;
    font-size: 1em; }

::placeholder {
    line-height: normal; }

select {
    width: 100%; }

.hide-visually,
.sr-only {
    @extend %hide-visually; }

.hide-text {
    @extend %hide-text; }

// Show/hide elements in JavaScript

.is-visible {
    display: block !important; }

.is-hidden {
    display: none !important; }

.loading,
.loading input,
.loading a {
    cursor: wait !important; }



// ! ---------- TEXT SELECTION


::selection {
    background: palette('selection'); }

::-moz-selection {
    background: palette('selection'); }



// ! ---------- IMAGE LAZYLOADING


// Fade in after load

.lazyload,
.lazyloading {
    opacity: 0; }

.lazyloaded {
    opacity: 1;
    transition: transition(opacity); }


// Blur lazyloaded images before unveiling

.lazyloadblur {
    opacity: 1;
    filter: blur(20px);
    transition: transition(filter);

    &.lazyloaded {
        filter: blur(0); } }


// Hide lazyload images on non JS browsers

.no-js .lazyload {
    display: none !important; }

.no-js noscript .lazyload {
    display: block !important;
    opacity: 1; }



// ! ---------- FLOATING


.clearfix {
    @include clearfix; }



// ! ---------- INTRINSIC RATIOS


.ratio-container {
    position: relative;
    height: 0;
    max-width: 100%;
    overflow: hidden;

    img, video, iframe, object, embed {
        position: absolute;
        top: -1px;
        left: -1px;
        @if true {
            width: 100%;
            height: 100%;
            width: calc(100% + 2px);
            height: calc(100% + 2px); } }

    iframe {
        background-color: palette('background'); } }

.ratio-1-1 {
    padding-bottom: 100%; }
.ratio-3-2 {
    padding-bottom: 66.6%; }
.ratio-4-3 {
    padding-bottom: 75%; }
.ratio-5-3 {
    padding-bottom: 60%; }
.ratio-5-4 {
    padding-bottom: 80%; }
.ratio-16-9 {
    padding-bottom: 56.25%; }
.ratio-16-10,
.ratio-8-5 {
    padding-bottom: 62.5%; }



// ! ---------- TEXT BLOCKS


.text-block {

    a, a:hover {}

    u {
        text-decoration: none;
        background-image: linear-gradient(180deg, transparent, transparent 90%, currentColor 90.1%, currentColor 96%, transparent 96.1%, transparent);
        // border-bottom: 1px solid
 }        // text-underline-position: under

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-size: $fontsize-l;
        margin-bottom: $one-line-height--absolute; }

    p, ul, ol, blockquote, figure, img, address, .TextformatterVideoEmbed {
        margin-bottom: $one-line-height;
        &:last-child {
            margin-bottom: 0; } }

    figure img {
        margin-bottom: 0; }

    blockquote {
        small {
            display: inline-block;
            margin-top: $one-line-height--relative * .75; } }

    ul {
        display: table;
        list-style: none;

        > li {
            display: table-row;

            &::before {
                display: table-cell;
                padding-right: .5em;
                content: "•"; } } }

    ol {
        display: table;
        list-style: none;
        counter-reset: table-ol;

        > li {
            display: table-row;
            counter-increment: table-ol;

            &::before {
                display: table-cell;
                text-align: right;
                padding-right: .4em;
                content: counter(table-ol) "."; } } } }
